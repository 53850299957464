import { useMemo } from "react"
import type { Payload } from "@local/payload-client/src/types"
import { useI10n } from "@local/i10n"

export interface carouselImageType {
  key: string
  variantImageId?: string | null
  variantName?: string | null
  variantId?: string | null
  itemProps: {
    url: string
    alt: string
  }
  position: number
}

export const reducePossibleOptions = (
  options: Payload.ProductVariant["options"]
) => {
  const result = options.reduce(
    (obj, item) =>
      Object.assign(obj, {
        // @ts-ignore
        [item.option || item.optionName]: item.value || item.valueName,
      }),
    {}
  )
  Object.keys(result).sort()
  return result
}

export const useCarouselDataParser = (product: Payload.Product) => {
  const { country } = useI10n()

  // const current = product?.isBucket
  // ? selectedBucket?.product
  // : (product as Payload.Product)

  const productImages = useMemo(() => {
    if (!product) {
      return []
    }

    // TODO: Create a function to make this less painful and DRY compatible
    const images = []

    // If bundles, then add the regional images
    if (
      product.type === "bundle" &&
      product?.regionalImages &&
      product.regionalImages.length > 0
    ) {
      const regionalImages = product.regionalImages
        .map((image) => image.images)
        .flat()
        .filter((image) => image?.region?.includes(country))
      images.push(...regionalImages)
    }

    if (product?.images && product.images.length !== 0) {
      images.push(
        // ...product?.images?.filter((image) => image.type === "general"),
        ...product?.images?.filter((image) => image.type === "lifestyle")
        // ...product?.images?.filter((image) => image.type === "variant")
      )
    }

    return images
  }, [country, product])

  const variantImagesArray = useMemo(() => {
    const variantImagesIds = product?.variants
      ?.filter((variant) => variant?.image)
      .map((variant) => variant?.image?.id)

    const uniqueIds = variantImagesIds?.filter((element, index) => {
      return variantImagesIds.indexOf(element) === index
    })

    const parsedItems = uniqueIds?.map((id) => {
      return {
        id: id,
        url: "",
        possibleOptions: [] as any[],
      }
    })

    product?.variants?.map((variant) => {
      const reduced = reducePossibleOptions(variant.options)
      const currentIndex = parsedItems?.findIndex(
        (item) => item.id === variant?.image?.id
      )
      if (
        currentIndex &&
        currentIndex > -1 &&
        parsedItems?.[currentIndex]?.possibleOptions &&
        parsedItems !== undefined
      ) {
        parsedItems[currentIndex].url = variant.image?.url ?? ""
        parsedItems[currentIndex]?.possibleOptions.push(reduced)
      } else if (variant?.image) {
        parsedItems?.push({
          id: variant.image.id,
          url: variant.image.url,
          possibleOptions: [reduced],
        })
      }
    })
    return parsedItems
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  const carouselImages = useMemo(() => {
    const parsedCarouselImages: carouselImageType[] = []

    productImages?.map((image, i) =>
      parsedCarouselImages.push({
        key: `item-image-${i}-${image?.id}`,
        variantImageId: null,
        itemProps: {
          url: image?.image?.url as string,
          alt: (image as any).caption ?? "",
        },
        position: i,
      })
    )
    variantImagesArray?.reverse().map((variantImage, i) =>
      parsedCarouselImages.push({
        key: `item-image-${i}-${variantImage.id}`,
        variantImageId: variantImage.id as string,
        itemProps: {
          url: variantImage?.url as string,
          alt: "",
        },
        position: productImages.length + i,
      })
    )

    return parsedCarouselImages
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  const cataloggedVariantImages = useMemo(() => {
    const array = [] as {
      id: string
      name: string
      images: Payload.OptionImage
    }[]

    if (!product) {
      return array
    }

    product.options?.map((option) => {
      option.values.map((optionValue) => {
        if (optionValue.images && optionValue.images?.length) {
          array.push({
            id: optionValue.id,
            name: optionValue.name,
            images: optionValue.images,
          })
        }
      })
    })
    return array
  }, [product])

  const carouselOptionImages = [] as carouselImageType[]

  cataloggedVariantImages.map((variant) => {
    variant.images.map((image, i) => {
      carouselOptionImages.push({
        key: `item-option-image-${i}-${variant.id}`,
        variantName: variant.name,
        variantId: variant.id,
        variantImageId: image.id as string,
        itemProps: {
          url: image?.image.url as string,
          alt: "",
        },
        position: i,
      })
    })
  })

  return {
    carouselImages,
    variantImagesArray,
    productImages,
    carouselOptionImages,
  }
}
