import type { Currency } from "@local/i10n"
import type { Payload } from "@local/payload-client/src/types"

export const getPrice = (
  product?: Pick<Payload.Product, "origPrice" | "origPrices" | "prices">,
  currencyCode?: Currency | null
) => {
  if (!product || !product.prices) {
    return {
      fullPrice: null,
      discountPrice: null,
      discountPercentage: null,
    }
  }

  const priceFind = product.prices.sort(
    (a, b) => a.minQuantity - b.minQuantity
  )[0]

  const fullPrice =
    currencyCode && currencyCode !== "USD"
      ? (product.origPrices?.find((item) => item.currency === currencyCode)
          ?.price as number)
      : product.origPrice
  const discountPrice =
    currencyCode && currencyCode !== "USD"
      ? (priceFind?.[currencyCode] as number)
      : priceFind?.price

  const discountPercentage = discountPrice
    ? Math.round(((fullPrice - discountPrice) / fullPrice) * 100)
    : null

  const displayPrice = fullPrice > discountPrice ? discountPrice : fullPrice

  return {
    displayPrice,
    fullPrice,
    discountPrice,
    discountPercentage,
  }
}
