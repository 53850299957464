import { Payload } from "@local/payload-client/src/types"
import { ProductOption } from "../../types"

export const filterVariantByShownOptions = (
  variants: Payload.ProductVariant[],
  options: ProductOption[]
): Payload.ProductVariant[] => {
  return variants.filter((variant) =>
    variant.options.every((vo) => {
      const option = options.find((o) => o.name === vo.option)
      if (!option) {
        return false
      }
      const value = option.values.find((v) => v.name === vo.value)
      if (!value) {
        return false
      }
      return true
    })
  )
}
