import { create } from "zustand"

interface CartState {
  isOpen: boolean
  isAnimating: boolean
  toggleOpen: (target?: boolean) => void
}

export const useCartLayout = create<CartState>((set) => ({
  isOpen: false,
  isAnimating: false,
  toggleOpen: (target) => {
    set(() => ({ isAnimating: true }))
    const timeout_f = setTimeout(() => {
      set((state) => ({ isOpen: target || !state.isOpen }))
      clearTimeout(timeout_f)
    }, 1)
    const timeout_s = setTimeout(() => {
      set(() => ({ isAnimating: false }))
      clearTimeout(timeout_s)
    }, 501)
  },
}))
