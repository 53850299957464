import { Payload } from "@local/payload-client/src/types"
import { checkProductCurrency } from "./checkProductCurrency"
import { Currency } from "@local/i10n"

export const getPricesForCart = (
  product: Payload.Product,
  currency?: Currency
) => {
  if (!checkProductCurrency(product, currency)) {
    currency = undefined
  }

  const prices: { min: number; max?: number; value: number }[] = []

  if (product.prices && product.prices.length > 0) {
    for (const price of product.prices) {
      prices.push({
        min: price.minQuantity,
        max: price.maxQuantity,
        value: Math.round(
          (currency && currency !== "USD"
            ? price[currency] || price.price
            : price.price) * 100
        ),
      })
    }
  } else {
    const localizedPrice = product.origPrices?.find(
      (p) => p.currency === currency
    )?.price

    prices.push({
      min: 0,
      value: Math.round(
        currency && localizedPrice
          ? localizedPrice * 100
          : product.origPrice * 100
      ),
    })
  }

  return prices
}
