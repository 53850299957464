import { Payload } from "./types"
import useSWR, { SWRConfiguration } from "swr"
import { fetchPayloadLocal } from "./fetchPayload"
import { useMemo } from "react"

interface QueryError {
  errors: { message: string }[]
}

export function isQueryError(data: any): data is QueryError {
  return !data || data.hasOwnProperty("errors")
}
interface BaseQueryOptions {
  locale?: any
  depth?: number
  config?: SWRConfiguration
  initialData?: any
}

interface GetByIdProps extends BaseQueryOptions {
  id: string
}

export const useGetProductById = ({ id, locale, config }: GetByIdProps) => {
  const { data, error, isLoading, isValidating, mutate } = useSWR<
    Payload.Product | QueryError | null
  >(
    [`/products/${id}/`, locale ? locale : null, "?depth=1"],
    fetchPayloadLocal,
    config
  )
  return { data, error, isLoading, isValidating, mutate }
}

interface GetProductByIdsProps extends BaseQueryOptions {
  ids: Array<string>
}

export const useGetProductsById = ({
  ids,
  depth,
  locale,
  config,
}: GetProductByIdsProps) => {
  let querystring = `where[id][in]=${ids.join(",")}`
  if (depth) {
    querystring += `&depth=${depth}`
  }
  const { data, error, isLoading, isValidating, mutate } = useSWR<
    | {
        docs: Array<Payload.Product>
      }
    | QueryError
    | null
  >(
    ["/products/", locale ? locale : null, querystring],
    fetchPayloadLocal,
    config
  )

  return { data, error, isLoading, isValidating, mutate }
}

interface GetProductBySlugProps extends BaseQueryOptions {
  slug: string
}

export const useGetProductBySlug = ({
  slug,
  locale,
  config,
  initialData,
}: GetProductBySlugProps) => {
  const {
    data: internalData,
    error,
    isLoading,
    isValidating,
    mutate,
  } = useSWR<Payload.Product | null>(
    [
      `/products/`,
      locale ? locale : null,
      `where[slug][equals]=${slug}`,
      {
        flatten: true,
      },
    ],
    fetchPayloadLocal,
    config
  )

  const data: Payload.Product | null = useMemo(() => {
    if (!initialData && !internalData) {
      return null
    }
    if (
      (!internalData || internalData.hasOwnProperty("errors")) &&
      initialData
    ) {
      return initialData
    }

    return internalData
  }, [initialData, internalData])

  return { data, error, isLoading, isValidating, mutate }
}

export const useGetProducts = ({ locale, config }: GetProductByIdsProps) => {
  const { data, error, isLoading, isValidating, mutate } = useSWR<{
    docs: Array<Payload.Product>
  } | null>(["/products/", locale ? locale : null], fetchPayloadLocal, config)

  return { data, error, isLoading, isValidating, mutate }
}

export const useGetCategories = ({ locale, config }: BaseQueryOptions) => {
  const { data, error, isLoading, isValidating, mutate } = useSWR<{
    docs: Array<Payload.Categories>
  } | null>(["/categories/", locale ? locale : null], fetchPayloadLocal, config)

  return { data, error, isLoading, isValidating, mutate }
}

export const useGetCategory = ({ id, locale, config }: GetByIdProps) => {
  const { data, error, isLoading, isValidating, mutate } =
    useSWR<Payload.Category | null>(
      [`/categories/${id}`, locale ? locale : null],
      fetchPayloadLocal,
      config
    )

  return { data, error, isLoading, isValidating, mutate }
}
